import React, { useCallback, useState, useEffect, useMemo } from 'react'

import { Alert, Button as ReferaButton, Dialog, Loader, Toast, useConfirm } from '@refera/ui-web'
import { Danger as DangerIcon, TickCircle as SuccessIcon } from '@refera/ui-icons'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { navigate, useLocation, useParams } from '@reach/router'

import { Grid, InputLabel, TextField, Collapse, Typography } from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'

import {
  approveBudgetLoadingSelector,
  getBudgetByIdSelector,
  getPayerOptionsSelector,
  getStepStatusLogSelector,
  updateBudgetErrorSelector,
  updateBudgetLoadingSelector,
} from '_modules/budget/selectors'
import {
  updateBudget,
  getPayerOptions,
  approveBudget,
  getBudget,
  getStepStatusLog,
  verifyPaidInstallment,
} from '_modules/budget/actions'
import { getInstallmentRules } from '_/modules/finance/actions'
import { getInstallmentRulesSelector } from '_/modules/finance/selectors'
import Button from '_components/button'
import TextFieldComponent from '_components/textfield'
import { userSelector } from '_modules/authentication/selectors'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import { EMAIL_REGEX, ROUTES } from '_utils/constants'
import { BUTTONS_ACTION, ROLE_GROUP_CHOICES, STEP_STATUS } from '_utils/constants/service-order'
import {
  currentServiceOrderSelector,
  getButtonsActionByNameSelector,
} from '_modules/service-orders/selectors'
import { cpfCnpjMask, ERRORS, removeCountryId, transformData, validateFormCpfCnpj } from './utils'
import InputField from './input-field'
import { getButtonsAction, getServiceOrder } from '_/modules/service-orders/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { agencySelector } from '_/modules/agency/selectors'
import { getAgency } from '_/modules/agency/actions'
import { AlertTitle } from '@material-ui/lab'
import SelectField from '../components/SelectField'
import { formatErrorMessage } from '../utils/FormatErrorMessage'
import ConfirmationDialog from '_/components/help-requests/modals/confirmation-dialog'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { getMessagingByServiceOrderId } from '_/modules/mensageria/actions'
import { openWhatsAppWithMessage } from '_/utils/open-whatsApp'

const PayerData = () => {
  const styles = useStyles()
  const loadingBudget = useSelector(updateBudgetLoadingSelector)
  const errorBudget = useSelector(updateBudgetErrorSelector)
  const loadingApprove = useSelector(approveBudgetLoadingSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const { checkIfUserDoesNotPermission, isSAAS } = useRolePermission()

  const { pathname } = useLocation()
  const { budgetId, serviceOrderId } = useParams()

  const nameButton = useMemo(() => BUTTONS_ACTION.EDIT_PAYER_DATA, [])
  const initialModal = useMemo(() => {
    return { isOpen: false, message: '', icon: '', type: 'info', action: () => {} }
  }, [])
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const options = useSelector(getPayerOptionsSelector)
  const buttonAction = useSelector(getButtonsActionByNameSelector(nameButton))
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const agency = useSelector(agencySelector)
  const installmentRules = useSelector(getInstallmentRulesSelector)
  const agencyId = useMemo(() => serviceOrder?.agency?.get('id'), [serviceOrder?.agency])
  const [personPayingEmailError, setPersonPayingEmailError] = useState(null)
  const [emailInvoiceError, setEmailInvoiceError] = useState('')
  const [payer, setPayer] = useState({})
  const [modal, setModal] = useState(initialModal)
  const [error, setError] = useState({ isOpen: false, message: '' })
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const [hasPaidInstallment, setHasPaidInstallment] = useState(true)
  const [alertMessage, setAlertMessage] = useState('')
  const { isConfirmed } = useConfirm()
  const isCsRefera = user?.getRoles?.includes('cs_refera')

  const [loadingButtons, setLoadingButtons] = useState(false)

  const currentBudgetIsApproved = useMemo(() => budget?.get('approved'), [budget])
  const canEditPayerData = useCanDoButtonAction({ nameButton })
  const isFromSAASAgencyAndNotReferaService = useMemo(() => {
    const parsedServiceOrder = serviceOrder?.toJS()
    return (
      parsedServiceOrder?.isReferaService === false &&
      parsedServiceOrder?.agency?.contractType === 'SAAS'
    )
  }, [serviceOrder])
  const isApproval = useMemo(() => pathname.slice('/').includes('aprovar'), [pathname])
  const { watch, errors, register, control, handleSubmit, setValue, getValues } = useForm({
    mode: 'all',
  })
  const data = watch()
  const watchCPF = watch('responsibleDocNumber', payer?.responsibleDocNumber || '')

  // hidden invoice fields
  const watchInvoiceDocNumber = watch('invoiceDocNumber', payer?.invoiceDocNumber || '')
  const watchInvoiceName = watch('nameInvoice', payer?.nameInvoice || '')
  const watchInvoiceEmail = watch('emailInvoice', payer?.emailInvoice || '')
  const watchTaxRegime = watch('taxRegime', payer?.taxRegime || '')

  const isWaitingPaymentInfo = useMemo(
    () => serviceOrder?.get('stepStatus') === STEP_STATUS.WAITING_PAYMENT_INFO,
    [serviceOrder]
  )

  const initialState = useCallback(
    initialData => {
      register('regime')
      register('taxRegime')
      register('nameInvoice')
      register('emailInvoice')
      register('payCollector')
      register('formOfPayment')
      register('responsibleName')
      register('recipientInvoice')
      register('invoiceDocNumber')
      register('personPayingEmail')
      register('paymentResponsible')
      register('whatsappResponsible')
      register('installmentQuantity')
      register('responsibleDocNumber')

      const payCollectorInitialValue = isFromSAASAgencyAndNotReferaService
        ? 'intermediary_collector'
        : initialData?.payCollector
      setValue('payCollector', payCollectorInitialValue || '')
      setValue('formOfPayment', initialData?.formOfPayment || '')
      setValue('responsibleName', initialData?.responsibleName || '')
      setValue('recipientInvoice', initialData?.recipientInvoice || '')
      setValue('personPayingEmail', initialData?.personPayingEmail || '')
      setValue('paymentResponsible', initialData?.paymentResponsible || '')
      setValue('installmentQuantity', initialData?.installmentQuantity || '')
      setValue('whatsappResponsible', initialData?.whatsappResponsible || '')
      setValue('responsibleDocNumber', initialData?.responsibleDocNumber || '')
      // ---- INVOICE
      setValue('regime', initialData?.regime || '')
      setValue('taxRegime', initialData?.taxRegime || '')
      setValue('nameInvoice', initialData?.nameInvoice || '')
      setValue('emailInvoice', initialData?.emailInvoice || '')
      setValue('invoiceDocNumber', initialData?.invoiceDocNumber || '')
    },
    [budget, payer, data, isFromSAASAgencyAndNotReferaService]
  )

  const handleGetBudget = useCallback(() => {
    dispatch(getBudget(serviceOrderId))
  }, [serviceOrderId])

  const handleGetAgency = useCallback(() => {
    dispatch(getAgency(agencyId))
  }, [dispatch, agencyId])

  const handleGetButtonsAction = useCallback(() => {
    if (!buttonAction) dispatch(getButtonsAction())
    if (!stepStatusLog?.length) dispatch(getStepStatusLog(serviceOrderId, budgetId))
  }, [dispatch, buttonAction, stepStatusLog?.length, serviceOrderId, budgetId])

  const handleVerifyPaidInstallment = async () => {
    await Promise.resolve(dispatch(verifyPaidInstallment(serviceOrderId, budgetId)))
      .then(() => setHasPaidInstallment(false))
      .catch(() => {
        setError({
          isOpen: true,
          message: ERRORS.CANNOT_EDIT_BUDGET_PAID,
        })
        setHasPaidInstallment(true)
      })
  }

  useEffect(() => {
    if (!serviceOrder) dispatch(getServiceOrder(serviceOrderId))
    if (!budget) handleGetBudget()
  }, [budget, serviceOrder])

  useEffect(() => {
    if (agencyId && (!agency || agency?.id !== agencyId)) {
      handleGetAgency()
    }
  }, [agencyId, agency])

  useEffect(() => {
    if (!buttonAction) handleGetButtonsAction()
    if (!installmentRules && budgetId) dispatch(getInstallmentRules({ budgetId }))
  }, [buttonAction, installmentRules, budgetId])

  useEffect(() => {
    dispatch(getPayerOptions(serviceOrderId, budgetId))
    handleVerifyPaidInstallment()
  }, [budgetId, dispatch, serviceOrderId])

  useEffect(() => {
    if (budget?.budgetPayer) {
      const initialData = budget?.toJS().budgetPayer[0]
      setPayer(initialData)
      initialState(initialData)
    }
  }, [budget?.budgetPayer])

  useEffect(() => {
    if (isWaitingPaymentInfo) {
      setToast({
        isOpen: true,
        message: 'Dados requeridos porque o chamado está na etapa "Aguardando dados de pagamento"',
        type: 'info',
      })
    }
  }, [isWaitingPaymentInfo])

  const handleBackNavigation = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
    return navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}`)
  }, [navigate, serviceOrderId, budgetId, dispatch])

  const PAYER_OPTIONS = options?.paymentResponsible
  const REGIMEN_OPTIONS = options?.regime
  const CHARGING_OPTIONS = options?.payCollector
  const PAYMENT_OPTIONS = options?.paymentMethod
  const INSTALLMENT_OPTIONS = options?.installmentOptions
  const INSTALLMENT_OPTIONS_WITHOUT_INTEREST = options?.installmentOptionsWithoutInterest

  const installmentSelectOptions = useMemo(() => {
    return data?.formOfPayment === 'realestate_installment'
      ? INSTALLMENT_OPTIONS_WITHOUT_INTEREST
      : INSTALLMENT_OPTIONS
  }, [data?.formOfPayment, INSTALLMENT_OPTIONS_WITHOUT_INTEREST, INSTALLMENT_OPTIONS])

  const RECIPIENT_OPTIONS = [
    {
      option: 'Responsável pelo pagamento',
      status: 'responsible_above',
    },
    {
      option: 'Outro',
      status: 'other',
    },
  ]

  const isSubmitDisabled = useMemo(() => {
    if (!canEditPayerData || hasPaidInstallment) {
      return true
    }
    return false
  }, [hasPaidInstallment, canEditPayerData])

  const handleAutoCompleteResponsible = useCallback(() => {
    if (data?.paymentResponsible === 'requester' && payer?.paymentResponsible !== 'requester') {
      setValue('responsibleName', serviceOrder?.contactName || '')
      setValue('personPayingEmail', serviceOrder?.contactEmail || '')
      setValue(
        'whatsappResponsible',
        serviceOrder?.contactNumber ? removeCountryId(serviceOrder?.contactNumber) : ''
      )
      setValue(
        'responsibleDocNumber',
        serviceOrder?.contactCpfCnpj ? cpfCnpjMask(serviceOrder?.contactCpfCnpj) : ''
      )
      return
    }
    if (data.paymentResponsible === 'owner' && payer?.paymentResponsible !== 'owner') {
      setValue('responsibleName', serviceOrder?.propertyName || '')
      setValue('personPayingEmail', serviceOrder?.propertyEmail || '')
      setValue(
        'whatsappResponsible',
        serviceOrder?.propertyWhatsapp ? removeCountryId(serviceOrder?.propertyWhatsapp) : ''
      )
      setValue(
        'responsibleDocNumber',
        serviceOrder?.propertyCpfOrCnpj ? cpfCnpjMask(serviceOrder?.propertyCpfOrCnpj) : ''
      )

      if (payer?.personPayingEmail) {
        if (!isValidEmail(payer?.personPayingEmail)) {
          setPersonPayingEmailError('Invalid Person Paying Email')
        } else {
          setPersonPayingEmailError(null)
        }
      }
      return
    }

    if (
      data.paymentResponsible === 'intermediary' &&
      payer?.paymentResponsible !== 'intermediary'
    ) {
      setValue('responsibleName', agency?.personResponsibleName || '')
      setValue('personPayingEmail', agency?.contactEmail || '')
      setValue(
        'whatsappResponsible',
        agency?.phoneNumber ? removeCountryId(agency?.phoneNumber) : ''
      )
      setValue('responsibleDocNumber', agency?.cpfCnpj ? cpfCnpjMask(agency?.cpfCnpj) : '')
      return
    }

    setValue('responsibleName', payer?.responsibleName || '')
    setValue('personPayingEmail', payer?.personPayingEmail || '')
    setValue(
      'whatsappResponsible',
      payer?.whatsappResponsible ? removeCountryId(payer?.whatsappResponsible) : ''
    )
    setValue(
      'responsibleDocNumber',
      payer?.responsibleDocNumber ? cpfCnpjMask(payer?.responsibleDocNumber) : ''
    )
  }, [data?.paymentResponsible, serviceOrder, payer, agency])

  useEffect(() => {
    handleAutoCompleteResponsible()
  }, [data?.paymentResponsible])

  const isValidEmail = email => {
    return EMAIL_REGEX.test(email)
  }

  const handleChange = event => {
    if (event.target.name === 'personPayingEmail') {
      if (!isValidEmail(event.target.value)) {
        setPersonPayingEmailError('Invalid Person Paying Email')
      } else {
        setPersonPayingEmailError(null)
      }
    }

    if (event.target.name === 'emailInvoice') {
      if (!isValidEmail(event.target.value)) {
        setEmailInvoiceError('Invalid Invoice Email')
      } else {
        setEmailInvoiceError(null)
        setValue('emailInvoice', event.target.value)
      }
    }
  }

  useEffect(() => {
    if (payer?.emailInvoice) {
      setEmailInvoiceError(null)
    }
    if (payer?.personPayingEmail) {
      setPersonPayingEmailError(null)
    }
  }, [payer?.emailInvoice, payer?.personPayingEmail])

  const handleCloseModal = useCallback(() => {
    setModal(old => ({ ...old, isOpen: false }))
  }, [setModal, initialModal])

  const handleInstallmentQuantity = useCallback(
    async installmentQuantity => {
      if (isCsRefera) {
        if (installmentRules) {
          const higherInstallment = installmentRules?.installment

          if (higherInstallment && installmentQuantity > higherInstallment) {
            setAlertMessage(
              `A quantidade do parcelamento é maior que a quantidade máxima permitida em função do valor do orçamento (${higherInstallment} parcela${
                higherInstallment > 1 ? 's' : ''
              }).\nVocê confirma esta informação?`
            )
            const confirmed = await isConfirmed()
            return confirmed
          }
          return true
        }
      } else {
        if (installmentQuantity === 9 || installmentQuantity === 10) {
          setToast({
            isOpen: true,
            type: 'error',
            message:
              'Pedimos desculpas, mas o parcelamento em 9 ou 10 vezes só pode ser escolhido pelos colaboradores da Refera.',
          })

          return false
        }
        if (installmentQuantity < 9) {
          if (installmentRules) {
            const higherInstallment = installmentRules?.installment
            if (
              installmentRules.minValue <= budget.priceTotal &&
              budget.priceTotal <= installmentRules.maxValue
            ) {
              if (installmentQuantity > higherInstallment) {
                setToast({
                  isOpen: true,
                  type: 'error',
                  message: `Por regras da Refera, e com base no valor do orçamento, seu pagamento só pode ser parcelado em até ${higherInstallment} vezes.`,
                })
                return false
              }
            }
            return true
          }
        }
        return false
      }
      return true
    },
    [installmentRules, budget]
  )

  const onFinalizesUpdateBudget = useCallback(
    async (updateError = false) => {
      setLoadingButtons(true)
      if (updateError) {
        if (errorBudget?.get('errorCode')) {
          let errorMessage = ''

          if (errorBudget?.get('errorCode') === 'forbidden_user_data') {
            errorMessage = ERRORS.UNAUTHORIZED_EMAIL
          } else {
            errorMessage = formatErrorMessage(errorBudget?.get('errorCode'))
          }

          setModal({
            isOpen: true,
            message: errorMessage,
            icon: DangerIcon,
            type: 'info',
            action: handleCloseModal,
          })
        }
        return setLoadingButtons(false)
      }

      if (isApproval && budget?.id) {
        const responsibleNameValue = getValues('responsibleName')
        const recipientInvoiceValue = getValues('recipientInvoice')
        const personPayingEmailValue = getValues('personPayingEmail')
        const responsibleDocNumberValue = getValues('responsibleDocNumber')
        const emailInvoiceValue = getValues('emailInvoice')
        const invoiceDocNumberValue = getValues('invoiceDocNumber')

        const payload = {
          responsibleName: responsibleNameValue,
          ...(recipientInvoiceValue === 'responsible_above'
            ? {
                personPayingEmail: personPayingEmailValue,
                personPayingCpf: responsibleDocNumberValue,
              }
            : {
                personPayingEmail: emailInvoiceValue,
                personPayingCpf: invoiceDocNumberValue,
              }),
        }

        return dispatch(approveBudget(serviceOrderId, budget?.id, payload))
          .then(() => {
            handleGetBudget()
            dispatch(getServiceOrder(serviceOrderId))
            if (isSAAS && serviceOrder?.isReferaService === false) {
              dispatch(
                getMessagingByServiceOrderId({
                  serviceOrderId,
                  destination: ROLE_GROUP_CHOICES.TRADESMAN_GROUP,
                  stepStatus: STEP_STATUS.WAITING_SERVICE_SCHEDULE,
                })
              )
                .then(response => {
                  if (serviceOrder?.tradesmanPhone && response?.whatsappText) {
                    openWhatsAppWithMessage({
                      phone: serviceOrder?.tradesmanPhone,
                      message: response.whatsappText,
                    })
                  }
                })
                .catch(() => {
                  console.error('Erro na requisição de procurar um registro de mensageria.')
                })
            }

            setModal({
              isOpen: true,
              message: 'Orçamento aprovado com sucesso.',
              icon: SuccessIcon,
              type: 'success',
              action: handleBackNavigation,
            })
            return setLoadingButtons(false)
          })
          .catch(() => {
            setToast({
              isOpen: true,
              message: 'Ocorreu um erro ao realizar a operação.',
              type: 'error',
            })
            onFinalizesUpdateBudget({ updateError: true })

            return setLoadingButtons(false)
          })
      }

      return setLoadingButtons(false)
    },
    [
      budget?.id,
      dispatch,
      isApproval,
      serviceOrderId,
      setToast,
      handleGetBudget,
      errorBudget,
      handleCloseModal,
      serviceOrder?.tradesmanPhone,
      isSAAS,
      serviceOrder?.isReferaService,
    ]
  )

  const handleCheckErasedField = () => {
    if (currentBudgetIsApproved && payer) {
      return Object?.keys(data).some(field => data[field] === '' && payer[field])
    }
    return false
  }

  const handleFormHasErrors = useCallback(() => {
    let message = ''

    message = validateFormCpfCnpj(data)

    if (!!errors.whatsappResponsible || data?.whatsappResponsible.includes('_'))
      message = ERRORS.INVALID_TELEPHONE

    if (handleCheckErasedField()) {
      message = ERRORS.EMPTY_FIELD
    }

    if (handleCheckErasedField()) {
      message = ERRORS.EMPTY_FIELD
    }

    if (personPayingEmailError || (data?.recipientInvoice === 'other' && emailInvoiceError)) {
      message = ERRORS.INVALID_EMAIL
    }

    if (message.length > 0) {
      setError({
        isOpen: true,
        message,
      })
      return true
    }

    setError({
      isOpen: false,
      message: '',
    })
    return false
  }, [setError, data, errors, handleCheckErasedField, personPayingEmailError, emailInvoiceError])

  const onSubmit = useCallback(
    async dataValues => {
      if (
        checkIfUserDoesNotPermission(PERMISSIONS.EDIT_PAYER_DATA, [
          PERMISSIONS_ACTIONS.ADD,
          PERMISSIONS_ACTIONS.EDIT,
        ])
      ) {
        return null
      }

      const request = dataValues
      const isInstallmentValid = await handleInstallmentQuantity(dataValues?.installmentQuantity)

      if (!isInstallmentValid) {
        return false
      }
      setLoadingButtons(true)

      if (isSubmitDisabled) {
        setToast({
          isOpen: true,
          message:
            'Não é possível salvar alterações pois o chamado se encontra em uma etapa que não permite edição dos dados do pagador.',
          type: 'error',
        })

        return setLoadingButtons(false)
      }

      if (!isApproval && !canEditPayerData) {
        return setLoadingButtons(false)
      }

      if (handleFormHasErrors()) return setLoadingButtons(false)

      request.budget = Number(budgetId)
      request.keepPrivateData = false
      request.personPayingWhatsapp = dataValues.whatsappResponsible
      request.whatsappResponsible = undefined

      if (dataValues?.recipientInvoice === 'responsible_above') {
        request.nameInvoice = dataValues.responsibleName
        request.invoiceDocNumber = dataValues.responsibleDocNumber
        request.emailInvoice = dataValues.personPayingEmail
        request.taxRegime = dataValues.responsibleDocNumber.length === 18 ? dataValues.regime : ''
      }

      if (dataValues?.responsibleDocNumber?.length !== 18) {
        request.regime = ''
      }

      if (dataValues?.invoiceDocNumber?.length !== 18) {
        request.taxRegime = ''
      }

      if (dataValues?.installmentQuantity === '') request.installmentQuantity = 0

      return dispatch(updateBudget(serviceOrderId, budgetId, transformData(request)))
        .then(() => {
          return onFinalizesUpdateBudget()
        })
        .catch(e => {
          setToast({
            isOpen: true,
            message: e?.error_message ?? 'Ocorreu um erro ao realizar a operação.',
            type: 'error',
          })
          return onFinalizesUpdateBudget({ updateError: true })
        })
    },
    [serviceOrderId, budgetId, handleFormHasErrors, isSubmitDisabled, handleInstallmentQuantity]
  )

  const handleCloseToast = useCallback(() => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }, [setToast])

  return (
    <>
      <Loader
        hasBackdrop
        open={loadingBudget || loadingApprove || loadingButtons}
        label="Aguarde..."
      />
      <form id="payerForm" onSubmit={handleSubmit(onSubmit)}>
        <HeaderTitle title="Dados do pagador" backButtonAction={handleBackNavigation}>
          <Grid className={styles.headerButtons}>
            <Button
              className={styles.button}
              type="submit"
              form="payerForm"
              color="primary"
              variant="contained"
              disabled={loadingButtons}
              isLoading={loadingBudget || loadingApprove}
            >
              Salvar
            </Button>
            <ReferaButton
              variant="secondary"
              disabled={loadingButtons}
              color="red"
              onClick={handleBackNavigation}
            >
              Cancelar
            </ReferaButton>
          </Grid>
        </HeaderTitle>

        <Grid className={styles.container}>
          <Collapse in={error?.isOpen} className={styles.alert}>
            <Alert severity="info">
              <AlertTitle>{ERRORS.INVALID_FORM}</AlertTitle>
              {error?.message}
            </Alert>
          </Collapse>
          <Grid className={styles.subcontainer}>
            <InputLabel className={styles.label}>Dados do responsável pelo pagamento</InputLabel>
            <Grid className={styles.wrapper}>
              <SelectField
                id="payer"
                label="Responsável pelo pagamento"
                name="paymentResponsible"
                defaultValue={data?.paymentResponsible || ''}
                control={control}
                items={PAYER_OPTIONS}
                emptyItem
                required={isWaitingPaymentInfo}
                // required={(currentBudgetIsApproved || isApproval) && !data?.paymentResponsible}
              />
              <Grid className={styles.input}>
                <Typography className={styles.inputLabel}>
                  CPF/CNPJ do responsável
                  <Typography className={styles.asterisk}> *</Typography>
                </Typography>
                <TextFieldComponent
                  isCpfCnpj
                  name="responsibleDocNumber"
                  onChange={({ target: { value } }) =>
                    setValue('responsibleDocNumber', cpfCnpjMask(value))
                  }
                  value={watchCPF}
                  defaultValue={data?.responsibleDocNumber || ''}
                  // required={(currentBudgetIsApproved || isApproval) && !data?.responsibleDocNumber}
                  required
                  placeholder="000.000.000-00"
                  className={styles.inputSize}
                  InputProps={{
                    style: { fontSize: 16, height: 40, marginTop: 4 },
                    inputProps: { maxLength: 18, minLength: 13 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: styles.inputLabel,
                  }}
                />
              </Grid>
              <SelectField
                id="regime"
                label="Regime da empresa"
                name="regime"
                disabled={watchCPF.length !== 18}
                defaultValue={data?.regime || ''}
                control={control}
                items={REGIMEN_OPTIONS}
                emptyItem
                // required={(currentBudgetIsApproved || isApproval) && watchCPF.length === 18}
                required={watchCPF.length === 18}
              />
            </Grid>
            <Grid className={styles.wrapper}>
              <InputField
                id="responsibleName"
                name="responsibleName"
                register={register}
                type="text"
                placeholder="Nome"
                label="Nome do responsável"
                defaultValue={data?.responsibleName || ''}
                // required={(currentBudgetIsApproved || isApproval) && !data?.responsibleName}
                required
              />
              <InputField
                id="personPayingEmail"
                name="personPayingEmail"
                type="email"
                placeholder="Digite o e-mail"
                label="E-mail do responsável"
                register={register}
                defaultValue={data?.personPayingEmail || ''}
                // required={(currentBudgetIsApproved || isApproval) && !data?.personPayingEmail}
                required
                disabled={!data?.personPayingEmail}
                onChange={handleChange}
              />
              <Grid className={styles.input}>
                <Typography className={styles.inputLabel}>
                  Whatsapp do responsável
                  <Typography className={styles.asterisk}> *</Typography>
                </Typography>
                <Controller
                  control={control}
                  name="whatsappResponsible"
                  rules={{ required: true, minLength: 9 }}
                  defaultValue={data?.whatsappResponsible || ''}
                  as={
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar="_"
                      // required={
                      //   (currentBudgetIsApproved || isApproval) && !data?.whatsappResponsible
                      // }
                      required
                      InputLabelProps={{
                        shrink: true,
                        className: styles.inputLabel,
                      }}
                      InputProps={{
                        style: { fontSize: 16, height: 35, marginTop: 10 },
                        inputProps: { minLength: 9 },
                      }}
                    >
                      {inputProps => <TextField className={styles.inputSize} {...inputProps} />}
                    </InputMask>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={styles.subcontainer}>
            <InputLabel className={styles.label}>Dados do orçamento</InputLabel>
            <Grid className={styles.wrapper}>
              <SelectField
                id="payCollector"
                label="Responsável pela cobrança"
                name="payCollector"
                defaultValue={data?.payCollector || ''}
                control={control}
                items={CHARGING_OPTIONS}
                disabled={isFromSAASAgencyAndNotReferaService}
                emptyItem
                required={
                  (currentBudgetIsApproved || isApproval || isWaitingPaymentInfo) &&
                  !data?.payCollector
                }
              />
              <SelectField
                id="formOfPayment"
                label="Forma de pagamento"
                name="formOfPayment"
                defaultValue={data?.formOfPayment || ''}
                control={control}
                items={PAYMENT_OPTIONS}
                emptyItem
                required={
                  (currentBudgetIsApproved || isApproval || isWaitingPaymentInfo) &&
                  !data?.formOfPayment &&
                  data?.payCollector
                }
              />
              <SelectField
                emptyItem
                id="installmentQuantity"
                name="installmentQuantity"
                label="Parcelamento desejado"
                defaultValue={data?.installmentQuantity || ''}
                control={control}
                items={installmentSelectOptions}
                required={
                  (currentBudgetIsApproved || isApproval || isWaitingPaymentInfo) &&
                  !data?.installmentQuantity
                }
              />
            </Grid>
          </Grid>
          <Grid className={styles.subcontainer}>
            <InputLabel className={styles.label}>Dados da NFS-e</InputLabel>
            <Grid className={styles.wrapper}>
              <SelectField
                id="recipientInvoice"
                label="Emitir NFS-e para"
                name="recipientInvoice"
                defaultValue={data?.recipientInvoice || ''}
                control={control}
                items={RECIPIENT_OPTIONS}
                emptyItem
                required={
                  (currentBudgetIsApproved || isApproval || isWaitingPaymentInfo) &&
                  !data?.recipientInvoice
                }
              />
              {data?.recipientInvoice === 'other' && (
                <>
                  <Grid className={styles.input}>
                    <Typography className={styles.inputLabel}>
                      CPF/CNPJ do responsável
                      <Typography className={styles.asterisk}> *</Typography>
                    </Typography>
                    <TextFieldComponent
                      isCpfCnpj
                      name="invoiceDocNumber"
                      onChange={e => setValue('invoiceDocNumber', cpfCnpjMask(e.target.value))}
                      value={watchInvoiceDocNumber}
                      defaultValue={data?.invoiceDocNumber || ''}
                      required={data?.recipientInvoice === 'other'}
                      placeholder="000.000.000-00"
                      className={styles.inputSize}
                      InputProps={{
                        style: { fontSize: 16, height: 40, marginTop: 4 },
                        inputProps: { maxLength: 18, minLength: 13 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: styles.inputLabel,
                      }}
                    />
                  </Grid>
                  <InputField
                    id="nameInvoice"
                    name="nameInvoice"
                    onChange={e => setValue('nameInvoice', e.target.value)}
                    defaultValue={watchInvoiceName || ''}
                    control={control}
                    type="text"
                    placeholder="Nome"
                    label="Nome para NFS-e"
                    required={data?.recipientInvoice === 'other'}
                  />
                </>
              )}
            </Grid>
            {data?.recipientInvoice === 'other' && (
              <Grid className={styles.wrapper}>
                <InputField
                  id="emailInvoice"
                  name="emailInvoice"
                  type="email"
                  defaultValue={watchInvoiceEmail || ''}
                  control={control}
                  placeholder="Digite o e-mail"
                  label="E-mail para NFS-e"
                  required={data?.recipientInvoice === 'other'}
                  onChange={handleChange}
                />
                {data?.invoiceDocNumber?.length === 18 && (
                  <SelectField
                    id="taxRegime"
                    label="Regime tributário"
                    name="taxRegime"
                    defaultValue={watchTaxRegime || ''}
                    control={control}
                    items={REGIMEN_OPTIONS}
                    emptyItem
                    required={data?.invoiceDocNumber?.length === 18}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
      {alertMessage && <ConfirmationDialog type="warning" subject={alertMessage} />}
      <Dialog
        open={modal?.isOpen}
        icon={modal?.icon}
        type={modal?.type}
        subject={modal?.message}
        onApprove={modal?.action}
      />
      {toast.isOpen && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
        </Toast>
      )}
    </>
  )
}

export default PayerData
